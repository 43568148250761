import { AccountRes, AddressRes, TelephoneRes } from "@n3oltd/k2.accounts.sdk.public";
import { CartRes } from "@n3oltd/karakoram.cart.sdk.cart";
import { AccountType, CheckoutRes } from "@n3oltd/karakoram.checkout.sdk.checkout";

export interface Lookups {
  "accounts.title": AccountTitle[];
  "common.country": Country[];
  "communications.category": CommunicationCategory[];
  "communications.channel": Channel[];
  "dataEntry.textTransformations": TextTransformation[];
  "dataEntry.addressLayout": Layout[];
  "dataEntry.nameLayout": Layout[];
  "textRelief.processorType": ProcessorType[];
  "accounts.organisationsType": OrgType[]
}

export interface AccountTitle {
  Name: string;
  Id: string;
}

export interface Country {
  Iso2Code: string;
  Iso3Code: string;
  Currency: Currency;
  DialingCode: number;
  AlternativeNames: string[];
  DefaultLocalization: Localization;
  Name: string;
  Id: string;
}

export interface Currency {
  Code: string;
}

export interface Localization {
  NumberFormat: NumberFormat;
  DateFormat: DateFormat;
  TimeFormat: TimeFormat;
  Language: Language;
  Timezone: TimezoneInfo;
}

export interface NumberFormat {
  CultureCode: string;
  DecimalSeparator: string;
  ThousandsSeparator: string;
  Name: string;
  Id: "international" | "eu1" | "eu2";
}

export interface DateFormat {
  CultureCode: string;
  Pattern: "dmy" | "mdy" | "ymd";
  Separator: string;
  Name: string;
  Id: string;
}

export interface TimeFormat {
  HasMeridiem: boolean;
  CultureCode: string;
  Name: string;
  Id: "24" | "12";
}

export interface Language {
  Name: string;
  Id: string;
}

export interface TimezoneInfo {
  Zone: Timezone;
  UtcOffset: string;
  Name: string;
  Id: string;
}

export interface Timezone {
  Offset?: string;
  Name?: string;
  Id: string;
  MinOffset: string;
  MaxOffset: string;
}

export interface CommunicationCategory {
  Id: string;
  Group: CategoryGroup;
  Channels: CategoryChannel[];
  Name: string;
}

export interface CategoryGroup {
  Name: string;
  Id: string;
}

export interface CategoryChannel {
  Channel: Channel;
  DefaultPreference: Preference;
}

export interface Channel {
  Name: string;
  Id: "email" | "post" | "sms" | "telephone";
}

export interface Preference {
  Value: boolean | null;
  Name: "No Response" | "Opt-In" | "Opt-Out";
  Id: "noResponse" | "optIn" | "optOut";
}

export interface TextTransformation {
  Id: "lowercase" | "titlecase" | "uppercase";
}

export interface Layout {
  Id: "layout1" | "layout2";
}

export interface ProcessorType {
  SupportsClaims: boolean;
  Id: "type1" | "type2";
}

export interface OrgType {
  Name: string
  Id: string
}

export interface Branding {
  addressCountry: string;
  addressPostalCode: string;
  addressSingleLine: string;
  charityRegistration: string;
  logoUrl: string;
  organisationName: string;
}

export interface NameField {
  label: string;
  required: boolean;
  minimumLength?: number;
  transformation?: 'titlecase';
}

export interface AddressField {
  label: string;
  required: boolean;
}

export interface Address {
  line1: AddressField;
  line2: AddressField;
  line3: AddressField;
  locality: AddressField;
  administrativeArea: AddressField;
  postalCode: AddressField;
  country: AddressField;
  domesticCountry: string;
  addressLookupApiKey: string;
  layout: string;
}

export interface ConsentOption {
  channel: string;
  categories: string[];
  default: boolean;
}

export interface Consent {
  preferenceText: string;
  privacyText: string;
  options: ConsentOption[];
}

export interface TaxRelief {
  type: string;
  name: string;
  excludeOrganisations: boolean;
  identifier: string;
  text: string;
  declarationTermsId: string;
  schemeId: string;
}

export interface AccountsConfig {
  name: {
    title: NameField;
    first: NameField;
    last: NameField;
    layout: string;
  };
  address: Address;
  email: {
    required: boolean;
    validate: boolean;
  };
  telephone: {
    required: boolean;
    validate: boolean;
  };
  preferences: Consent;
  taxRelief: TaxRelief;
}

export interface PaymentMethodConfig {
  id: string;
  processorId: string;
  name: string;
  displayName: string;
  description?: string;
  iconId: string
  allowedCurrencies: string[];
  supportsRealtimePayments: boolean;
  supportsApplePay: boolean;
  supportsGooglePay: boolean;
  order: number;
  collectionDaysOfWeek: CollectionDayOfWeek[],
  collectionDaysOfMonth: CollectionDayOfMonth[],
  additionalData?: {
    tokenizationKey: string;
    checkoutKey: string;
    redirectUrl: string
  };
}

export interface DayInfo  {
  name: string;
  id: string; 
  token: string;
};

export interface CollectionDayOfMonth {
  dayOfMonth: DayInfo;
  earliestChargeDateForNewCredential: string;
};

export interface CollectionDayOfWeek {
  dayOfWeek: DayInfo;
  earliestChargeDateForNewCredential: string;
};

export interface PaymentsConfig {
  paymentMethods: PaymentMethodConfig[];
}

export interface TermsOfService {
  text: string;
  url: string;
}

export interface CheckoutProfileConfig {
  id: string;
  branding: Branding;
  accounts: AccountsConfig;
  payments: PaymentsConfig;
  termsOfService: TermsOfService;
  allowedCurrencies: string[];
  adverts: {imageUrl: string, link: string}[]
}

export type DonationType = 'oneOff' | 'regular' | 'scheduled' | 'oneOffAndRegular';

export enum DonationTypeEnum {
  OneOff = 'oneOff',
  Regular = 'regular',
  Scheduled = 'scheduled',
  OneOffAndRegular = 'oneOffAndRegular',
}

export enum PaymentType {
  Credit = 'creditCard',
  DirectDebit = 'directDebit',
  Instant = 'instantBank',
  ApplePay = 'applePay',
  GooglePay = 'googlePay',
  Paypal = 'paypal',
}

export type PaymentMethod = PaymentType | null;

export type DonationDetails = {
  amount: number;
  giftAid: boolean;
};

export type PersonalInfo = {
  email?: string;
  phoneCode?: string;
  phone?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  area?: string;
  country?: string;
  postCode?: string;
  terms?: boolean;
  phoneCountry?: string;
  accountId?: string;
};

export enum StepScreens {
  Basket = 'Basket',
  CreditCard = 'CreditCard',
  DonationDetails = 'DonationDetails',
  DirectDebit = 'DirectDebit',
  DonationSummary = 'DonationSummary',
  BankTransfer =  'BankTransfer',
  PersonalInfo = 'PersonalInfo',
  ThankYou = 'ThankYou',
}

export type Step = keyof typeof StepScreens;

export type CheckoutState = {
  donationType: DonationType;
  paymentMethod: PaymentMethod | null;
  personalInfo: PersonalInfo | null;
  currentStep: Step;
  currentDonationFlow: DonationKeys;
  selectedPaymentMethod: PaymentMethod;
  availablePaymentMethods: PaymentMethod[];
  paymentPaid: {
    oneOff: boolean;
    regular: boolean;
    scheduled: boolean;
  },
  checkoutProfile?: CheckoutProfileConfig | null;
  checkoutSession: CheckoutRes,
  checkoutInfo: Partial<CheckoutInfo>
  cart: CartRes | null,
  legacy:boolean
  hasPayitHash?: boolean;
  onError?: (error: any) => void;
  checkoutProfileId: string
};

export type CheckoutInfo = {
  accountType: AccountType,
  isOrgSelected: boolean;
  org: {
    name?: string;
    type?: string;
    contact?: {
      title?: string | undefined;
      firstName?: string | undefined;
      lastName?: string | undefined;
    }
  };
  giftAidSelection: string;
  prefs: Record<string, any>;
  account: {
    name: {
      first: string;
      last: string;
      title: string;
    };
    email: string;
    phone: string;
    address: {
      line1?: string | undefined;
      line2?: string | undefined;
      line3?: string | undefined;
      locality?: string | undefined;
      administrativeArea?: string | undefined;
      postalCode?: string | undefined;
      country?: Country;
    };
  };
};


export type DonationKeys = 'donation' | 'regularGiving' | 'scheduledGiving';
export interface PersonalInfoFormProps {
  state: CheckoutState;
  updateState: (newState: Partial<CheckoutState>) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}

export interface PersonalInfoState {
  isManualAddress: boolean;
  addressFields: string[];
  showOTP: boolean;
  verificationFor: 'email' | 'phone' | null;
  isWrongOTP: boolean;
  showAccountSelection: boolean;
  isOTPVerified: boolean;
  phoneCountry: string,
  accounts: AccountRes[],
  loadings: {
    fetchingOTP?: boolean,
    verifyingOTP?: boolean,
    loadingAccounts?: boolean,
  },
  accountId: string | undefined,
  skip: boolean
}

export interface DonorRecord {
  name: string;
  phone?: TelephoneRes | undefined;
  address: AddressRes;
  reference: string;
  created: string;
}


export interface InitiatedBy {
  isTrusted: boolean;
}

export interface Card {
  number: string | null;
  bin: string | null;
  exp: string | null;
  type: string; // Could be an enum if card types are predefined
  hash: string | null;
}

export interface Check {
  name: string | null;
  account: string | null;
  aba: string | null;
  transit: string | null;
  institution: string | null;
  hash: string | null;
}

export interface BillingInfo {
  address1: string | null;
  address2: string | null;
  firstName: string;
  lastName: string;
  postalCode: string | null;
  city: string | null;
  state: string | null;
  country: string;
  phone: string;
}

export interface ShippingInfo {
  address1: string | null;
  address2: string | null;
  firstName: string | null;
  lastName: string | null;
  postalCode: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  phone: string | null;
}

export interface Wallet {
  cardDetails: string;
  cardNetwork: string; // Could be an enum if card networks are predefined
  email: string;
  billingInfo: BillingInfo;
  shippingInfo: ShippingInfo;
}

export interface CollectJSPaymentToken {
  tokenType: 'googlePay' | 'applePay';
  token: string;
  initiatedBy: InitiatedBy;
  card: Card;
  check: Check;
  wallet: Wallet;
}


declare global {
  interface Window {
    Gateway: any;
    CollectJS: any;
    themeConfig: any
  }
}
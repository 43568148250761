import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { Label, Input, Button } from '@n3oltd/n3o-ui-components';
import { AccountType, CheckoutRes, Country } from '@n3oltd/karakoram.checkout.sdk.checkout';

import { Drawer } from './Drawer';
import { ServerError } from '@/common/components/Error';
import { OTPVerification } from './account/OTPVerification';
import { AccountSelection } from './account/AccountSelection';
import { AddressLayout1 } from './account/layouts/address/Layout1';
import { AddressLayout2 } from './account/layouts/address/Layout2';
import { NameLayout1 } from './account/layouts/name/Layout1';
import { NameLayout2 } from './account/layouts/name/Layout2';
import { LoadingOverlay } from '@/Loader';
import { PhoneWithCountry } from './account/PhoneWithCountry';
import { useTranslation } from '@/i18n';
import { useAddressManagement, useAccountManagement, useOTPVerification } from '../hooks';
import { useApi } from '@/api/common/hooks/useApi';
import { _checkoutClient } from '@/api/common/clients/K2RestClient';
import { K2RestService } from '@/api/common/clients/K2RestService';
import { hasAddress } from '../utils/address';
import { Address, PersonalInfoFormProps, PersonalInfoState } from '../types';

import './personalInfo.css';

const Layouts: Record<string, any> = {
  name: {
    layout1: NameLayout1,
    layout2: NameLayout2
  },
  address: {
    layout1: AddressLayout1,
    layout2: AddressLayout2
  }
}

type SchemaType = yup.ObjectSchema<{
  email: string;
  phoneCode: string;
  phone: string;
  title: string;
  firstName: string;
  lastName: string;
  [key: string]: string | boolean;
}>;

const inputClasses =
    'h-9 relative focus:z-10 focus-visible:ring-primary focus-visible:ring-offset-0';
  const selectClasses =
    'h-9 relative focus:z-10 focus-visible:ring-primary focus-visible:ring-offset-0 focus:ring-primary data-[state=open]:ring-primary data-[state=open]:ring-1';


export default function PersonalInfoForm({ state, updateState, goToNextStep, goToPreviousStep }: PersonalInfoFormProps) {
  const [infoState, setInfoState] = React.useState<PersonalInfoState>({
    isManualAddress: !!hasAddress(state.personalInfo || {}) || !state.checkoutProfile?.accounts.address.addressLookupApiKey,
    addressFields: ['addressLine1', 'city', 'postCode', 'country'],
    showOTP: false,
    verificationFor: 'email',
    isWrongOTP: false,
    showAccountSelection: false,
    isOTPVerified: false,
    phoneCountry: state.personalInfo?.phoneCountry || '',
    accounts: [],
    accountId: state.personalInfo?.accountId,
    skip: false,
    loadings: {
      fetchingOTP: false,
      loadingAccounts: false,
      verifyingOTP: false
    }
  });
  
  const { formatMessage } = useTranslation();

  const baseSchema = yup.object().shape({
    email: state.checkoutProfile?.accounts.email.required ?  yup.string().email(formatMessage('personalInfo.error.email')).required(formatMessage('personalInfo.error.emailRequired')) : yup.string().email(formatMessage('personalInfo.error.email')),
    phone: state.checkoutProfile?.accounts.telephone.required ? yup.string().required(formatMessage('personalInfo.error.phoneRequired')) : yup.string(),
    title: yup.string(),
    firstName: yup.string().min(state.checkoutProfile?.accounts?.name?.first?.minimumLength || 0, formatMessage('personalInfo.error.firstNameMin')),
    lastName: yup.string().min(state.checkoutProfile?.accounts?.name?.last?.minimumLength || 0, formatMessage('personalInfo.error.lastNameMin')),
    ...(infoState.isManualAddress && {
      addressLine1: yup.string(),
      city: yup.string(),
      postCode: yup.string(),
      country: yup.string(),
    })
  });

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [schema] = useState<SchemaType>(baseSchema as any);
  

  const {execute, isLoading: isUpdatingAccount, error} = useApi<CheckoutRes>({
    onSuccess: (data: CheckoutRes) => {
      updateState({checkoutSession: data});
      goToNextStep();
    },
  })

  const { control, handleSubmit, formState: { errors, isSubmitted }, trigger, getValues, setValue } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      ...state.personalInfo,
      addressSearch: ''
    }
  });

  const { toggleAddressMode } = useAddressManagement({
    infoState,
    updateInfoState,
    inputRef,
    setValue,
    apiKey: state.checkoutProfile?.accounts.address.addressLookupApiKey
  });

  const { findAddress, handleAccountSelected, mapToDonorRecords } = useAccountManagement({
    infoState,
    updateInfoState,
    setValue
  });

  const { handleOTPComplete, handleEmailBlur, handlePhoneBlur } = useOTPVerification({
    infoState,
    updateInfoState,
    getValues,
    trigger,
    findAddress,
    accountType: state.checkoutInfo.accountType,
    orgName: state.checkoutInfo.org?.name,
  });

  const onSubmit = () => {
    const data = getValues();
    updateState({ personalInfo: {...data, phoneCountry: infoState.phoneCountry} });
  };

  function updateInfoState(newState: Partial<PersonalInfoState>){
    setInfoState(prev => ({ ...prev, ...newState})) 
  }

  const handleNextStep = async () => {
    try {
      const isValid = await trigger(['email', 'phone']);

      if (!isValid) {
        return;
      }

      const formValues = getValues();

      execute(_checkoutClient!.updateAccount(state.checkoutSession.revisionId || '', {
        type: state.checkoutInfo.accountType,
        id: infoState.accountId,
        submit: true,
        address: {
          administrativeArea: formValues.area as string,
          country: formValues.country as Country,
          line1: formValues.addressLine1 as string,
          line2: formValues.addressLine2 as string,
          line3: formValues.addressLine3 as string,
          locality: formValues.city as string,
          postalCode: formValues.postCode as string
        },
        email: {
          address: formValues.email as string
        },
        ...(state.checkoutInfo.accountType === AccountType.Individual ? {
          individual: {name: {
            firstName: formValues.firstName as string,
            lastName: formValues.lastName as string,
            title: formValues.title as string,
          },
        }}: {}),
        ...(state.checkoutInfo.accountType === AccountType.Organization ? {
          organization: {contact: {
            firstName: formValues.firstName as string,
            lastName: formValues.lastName as string,
            title: formValues.title as string,
          },
          name: state.checkoutInfo.org?.name,
          type: state.checkoutInfo.org?.type
        }} : {}),
        telephone: {
          country: infoState.phoneCountry as Country,
          number: formValues.phone as string
        },
      }));

      updateState({
        personalInfo: {...formValues, phoneCountry: infoState.phoneCountry, accountId: infoState.accountId || ''}
      });
      
    } catch (error) {
      console.error(error);
    }
  }

  if (infoState.showAccountSelection) {
    return <AccountSelection onAccountSelected={handleAccountSelected} accounts={mapToDonorRecords(infoState.accounts)} onSkip={() => updateInfoState({ showAccountSelection: false, isManualAddress: false})} />
  }

  const NameLayout = Layouts.name[state.checkoutProfile!.accounts?.name?.layout] || Layouts.name.layout1
  const AddressLayout = Layouts.address[state.checkoutProfile!.accounts?.address?.layout] || Layouts.address.layout1

  return (
    <>
    <LoadingOverlay
      isLoading={infoState.loadings.fetchingOTP || infoState.loadings.verifyingOTP || infoState.loadings.loadingAccounts || isUpdatingAccount}
      loadingText={formatMessage(isUpdatingAccount ? 'personalInfo.accountUpdate' :'common.loading')}
    />
    
    {infoState.showOTP && <OTPVerification 
      email={getValues('email') as string || ''}
      phone={getValues('phone') as string || ''}
      verificationFor={infoState.verificationFor}
      handleOTPComplete={handleOTPComplete}
      isWrongOTP={infoState.isWrongOTP}
      onSkip={() => updateInfoState({showOTP: false, verificationFor: null, isWrongOTP: false, skip: true})}
    />} 
    <Drawer.Header hideCloseButton>
      {formatMessage('personalInfo.heading')}
    </Drawer.Header>
    <Drawer.Content>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6" noValidate>
        <div className='flex flex-col sm:flex-row gap-4'>
          <div className="flex-1 space-y-2">
            <Label htmlFor="phone">{formatMessage('personalInfo.phone')}</Label>
            <div className="flex">
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <PhoneWithCountry
                    {...field}
                    field={field}
                    setValue={setValue}
                    country={infoState.phoneCountry || infoState.accounts.find(a => a.id === infoState.accountId)?.telephone?.country || state.checkoutProfile?.accounts.address.domesticCountry}
                    onCountryChange={country => country ?  infoState.phoneCountry = country : null}
                    onBlur={handlePhoneBlur}
                  />
                )}
              />
            </div>
            {(isSubmitted && errors.phone) && <p className="text-red-500 text-sm">{errors.phone.message}</p>}
          </div>
          <div className="flex-1 space-y-2">
            <Label htmlFor="email">{formatMessage('personalInfo.email')}</Label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input 
                  {...field}
                  value={field.value as string}
                  id="email" 
                  placeholder="username@example.com" 
                  className={inputClasses}
                  onBlur={handleEmailBlur}
                />
              )}
            />
            {(isSubmitted && errors.email) && <p className="text-red-500 text-sm">{errors.email.message}</p>}
          </div>
        </div>
        <NameLayout
          control={control} 
          formatMessage={formatMessage} 
          inputClasses={inputClasses} 
          selectClasses={selectClasses} />

        <AddressLayout
          control={control} 
          formatMessage={formatMessage} 
          inputClasses={inputClasses} 
          ref={inputRef} 
          isManualAddress={infoState.isManualAddress} 
          toggleAddressMode={toggleAddressMode}
          addressSchema={state.checkoutProfile?.accounts.address || {} as Address} />
      </form>
      {error?.errors && <ServerError error={error} />}
    </Drawer.Content>
    <Drawer.Footer>
      <div className="flex gap-2">
        <Button type="button" variant="outline" size="lg" className="w-12" onClick={() => {
          goToPreviousStep();
          K2RestService.setToken('');
        }}>
          <ArrowLeft className="h-4 w-4 text-gray-700" />
        </Button>
        <Button type="submit" size="lg" className="flex-1" onClick={() => {
            K2RestService.setToken('');
            handleSubmit(onSubmit)();
            handleNextStep()
          }
        }>
          {formatMessage('common.button.continue')}
          <ArrowRight className="h-4 w-4 ml-2" />
        </Button>
      </div>
    </Drawer.Footer>
    </>
  );
}